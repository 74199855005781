import React from "react"
import { Link } from "react-router-dom"
import "./not-found.css"

const Error = () => (
	<div className="page not-found">
		<div className="container">
			<h1>
				<img src="/images/404.svg" alt="Don’t Fear The 404 either" />
			</h1>
			<Link to="/" className="btn btn-yellow">
				<span className="ico ico-back ani-hor"></span>&nbsp;Back to safety
			</Link>
		</div>
	</div>
)

export default Error
