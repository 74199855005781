import React, { Component } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Home from "pages/home"
import Book from "pages/book"
import NotFound from "pages/not-found"

class Routes extends Component {
	render() {
		return (
			<Router>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route exact path="/book-appointment" component={Book} />
					<Route component={NotFound} />
				</Switch>
			</Router>
		)
	}
}

export default Routes
