import React, { Component, Fragment } from "react"
import { Link, withRouter } from "react-router-dom"
import { Helmet } from "react-helmet"
import { TweenMax, Circ, Linear } from "gsap/TweenMax"
import ScrollToPlugin from "gsap/ScrollToPlugin";
import Locations from "data/locations.json"
import "./book.css"

/* eslint-disable no-unused-vars*/
const plugins = [ScrollToPlugin];

class Book extends Component {
	constructor(props) {
		super(props)

		this.state = {
			locations: Locations,			
			province: null,
			centres: null,
			data: null
		}

		this.provinceSelect = React.createRef();
		this.centreSelect = React.createRef();

		this.populateCentres = this.populateCentres.bind(this);
		this.populateData = this.populateData.bind(this);
		this.resetData = this.resetData.bind(this);		
		this.animateIn = this.animateIn.bind(this);			
	}
	

	populateCentres(e) {	
		if (e.currentTarget.value === "default") {
			this.setState({
				province: null,
				centres: null,
				data: null
			})
		} else {
			if (this.state.data != null) {
				TweenMax.to(document.querySelector('.results'), 0.25, {opacity:0, onComplete: this.resetData});
			}
			this.setState({
				province: e.currentTarget.value,
				centres: Locations[e.currentTarget.value]
			})
		}
	}

	resetData() {
		this.setState({
			data: null
		})
	}

	populateData(e) {
		let currentProvince = this.state.province,
			currentCentre = e.currentTarget.value		

		if (e.currentTarget.value === "default") {
			TweenMax.to(document.querySelector('.results'), 0.25, {opacity:0, onComplete: this.resetData});
		} else {			
			this.setState({
				data: Locations[currentProvince][currentCentre]
			})	
		}
	}

	animateIn() {
		let offsetTop = document.querySelector('.results').offsetTop;

		TweenMax.to(document.querySelector('.results'), 0.5, {opacity:1})		
		TweenMax.to(document.querySelector('#root'), 0.5, {scrollTo:{y: offsetTop}});
	}

	componentDidMount() {
		TweenMax.from(".header", 0.5, { y: "-100%", ease: Circ.easeOut, delay: 0.2 })
		TweenMax.from(".content", 1.2, { opacity: 0, ease: Linear.easeIn, delay: 0.6 })
	}

	render() {
		const { locations, province, centres, data } = this.state

		const metaTitle = "Don’t Fear The Finger",
			metaUrl = window.location.href,
			metaDesc = "Click to find a CANSA Care Centre for a PSA test.",
			metaImage = "/images/share-image-v4.jpg"

		return (
			<div className="page book">
				<Helmet>
					<title>{metaTitle}</title>
					<link rel="canonical" href={metaUrl} />
					<meta name="description" content={metaDesc} />
					<meta property="og:url" content={metaUrl} />
					<meta property="og:title" content={metaTitle} />
					<meta property="og:description" content={metaDesc} />
					<meta property="og:image" content={metaImage} />
					<meta name="twitter:title" content={metaTitle} />
					<meta name="twitter:description" content={metaDesc} />
					<meta name="twitter:image" content={metaImage} />
					<meta name="twitter:image:alt" content={metaTitle} />
				</Helmet>
				<div className="container">
					<div className="header">
						<Link to="/" className="left tracker-campaign-logo">
							<img src="/images/campaign-logo.png" alt="Dont fear the finger" className="tracker-campaign-logo" />
						</Link>
						<a href="https://www.cansa.org.za/" target="_blank" rel="noopener noreferrer" className="right tracker-cansa-logo">
							<img src="/images/cansa-logo.png" alt="Cansa" className="tracker-cansa-logo" />
						</a>
					</div>
					<div className="content">						
						<div className="find">
							<h1>
								<img src="/images/find-a-care-centre.svg" alt="Find a care centre" />
							</h1>
							<p>
								If you’re over 40, your first prostate check can be a blood test.<br className="hide-mobi" />
								Get a PSA blood test at a CANSA Care Centre for <b className="color-yellow">R150.</b>
							</p>
							<div className="form">
								<div className="province has-select">
									<select ref={this.provinceSelect} onChange={this.populateCentres}>
										<Fragment>
											<option value="default">Select province</option>
											{
												Object.keys(locations).map((location, index) => <option key={`${location}-${index}`} value={location}>{location}</option>)
											}
										</Fragment>
									</select>				
								</div>		
								<div className={`centre${centres && " has-select"}`}>
									{
										centres &&																				
										<select ref={this.centreSelect} onLoad={this.populateData} onChange={this.populateData}>
											<Fragment>	
												<option value="default">Select care centre</option>
												{
													Object.keys(centres).map((location, index) => <option key={`${location}-${index}`} value={location}>{location}</option>)
												}
											</Fragment>
										</select>										
									}
								</div>		
							</div>
						</div>
						{ 
							(centres && data) &&
							<div className="results" onLoad={this.animateIn}>
								<h1>
									<img src="/images/book-a-blood-test.svg" alt="Book a blood test" />
								</h1>
								{
									data.name &&
									<p><b>{`CANSA ${data.name} Care Centre`}</b></p>
								}
								{
									(data.tel || data.email || data.directions) &&
									<div className="buttons">
										{
											data.tel &&
											<a href={`tel:${data.tel}`} title="call now" className="btn btn-yellow tracker-call"><span className="ico ico-call ani-hor tracker-call"></span>&nbsp;call now</a>
										}		
										{
											data.email &&
											<a href={`mailto:${data.email}?subject=Blood test booking enquiry - CANSA ${data.name} Care Centre - dontfearthefinger.co.za`} title="email" className="btn btn-yellow tracker-email"><span className="ico ico-email ani-vert tracker-email"></span>&nbsp;email us</a>
										}						
										{
											data.directions &&
											<a href={data.directions} title="get directions" target="_blank" rel="noopener noreferrer" className="btn btn-yellow tracker-directions"><span className="ico ico-direction ani-hor tracker-directions"></span>&nbsp;get directions</a>
										}									
									</div>		
								}					
								<p>
									Contact or visit us, we’d like to offer you &amp; your loved ones care &amp; support.									
									{
										data.address &&
										<Fragment>
											<br /><br />
											{data.address}
										</Fragment>
									}
									{
										data.post &&
										<Fragment>
											<br />
											{data.post}
										</Fragment>
									}									
									{
										data.tel &&	
										<Fragment>
											<br /><br />
											{data.tel}
										</Fragment>																			
									}
								</p>
								<a href="https://www.cansa.org.za/mens-health/" rel="noopener noreferrer" title="Cansa - Mens health" target="_blank" className="btn btn-link tracker-mens-health">Visit the Cansa Men’s Health page for more.</a>
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(Book)
