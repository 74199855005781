import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
import "./home.css"

class HomePage extends Component {
	constructor() {
		super()

		this.state = {
			videoLoaded: false
		}

		this.handleVideoLoaded = this.handleVideoLoaded.bind(this)
	}

	handleVideoLoaded() {
		setTimeout(() => {
			this.setState({
				videoLoaded: true
			})
		}, 1000);
	}

	render() {
		const { videoLoaded } = this.state
		const metaTitle = "Don’t Fear The Finger",
			metaUrl = window.location.href,
			metaDesc = "Over 40? Click to find a CANSA Care Centre for a PSA test.",
			metaImage = "/images/share-image-v4.jpg"

		return (
			<div className="page home">
				<Helmet>
					<title>{metaTitle}</title>
					<link rel="canonical" href={metaUrl} />
					<meta name="description" content={metaDesc} />
					<meta property="og:url" content={metaUrl} />
					<meta property="og:title" content={metaTitle} />
					<meta property="og:description" content={metaDesc} />
					<meta property="og:image" content={metaImage} />
					<meta name="twitter:title" content={metaTitle} />
					<meta name="twitter:description" content={metaDesc} />
					<meta name="twitter:image" content={metaImage} />
					<meta name="twitter:image:alt" content={metaTitle} />
				</Helmet>
				<div className="container">	
					<div className="video">
						<h1 className="title"><img src="/images/dont-fear.svg" alt="Don't fear the finger" /></h1>
						<img src="/images/loader.gif" alt="Dont Fear The Finger Loading" className={`${videoLoaded ? "loaded" : ""}`} />
						<video
							autoPlay
							loop
							muted
							playsInline
							placeholder="/images/video-still.jpg"							
							controls={false}							
							onLoadStart={this.handleVideoLoaded}
						>
							<source src="/video/home-animate.mp4" type="video/mp4" />
							<source src="/video/home-animate.webm" type="video/webm" />
						</video>
						<Link className="btn btn-find tracker-find-a-care-centre" to={{ pathname: "/book-appointment"}}></Link>
					</div>			
				</div>
			</div>
		)
	}
}

export default HomePage
